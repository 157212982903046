@keyframes bubble {
    0% {
        transform: translate(0, 0);
    }

    20% {
        transform: translate(-1px, -1px);
    }

    55% {
        transform: translate(0px, -1px);
    }

    75% {
        transform: translate(0px, 1px);
    }

    100% {
        transform: translate(2px, 1px);
    }
}

@keyframes bubbleHorizontal {
    100% {
        transform: translateX(-6px);
    }
}

@keyframes bubbleVertical {
    100% {
        transform: translateY(-2px);
    }
}

@keyframes float {
    0% {
        transform: translateY(0);
        opacity: 0;
    }

    20% {
        transform: translateY(0);
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        transform: translateY(-60px);
        opacity: 0;
    }
}

@keyframes goldensnitch {
    0% {
        transform: translate(0);
    }
    40% {
        transform: translate(4px, -4px);
    }
    50% {
        transform: translate(1px, 3px);
    }
    75% {
        transform: translate(-1px, -2px);
    }
    100% {
        transform: translate(-2px, -3px);
    }
}
