@import "./../../styles/colors";

.container {
    padding-top: 40px;

    .count,
    .add {
        transition: all 0.2s ease-out;
        user-select: none;
        text-align: center;
    }

    .count {
        height: 300px;
        width: 300px;
        background-color: $whiteColor;
        margin: 0 auto;
        line-height: 300px;
        font-size: 80px;
        font-weight: bold;
        border-radius: 60px;
        color: $mainColor;
        border: 8px solid $mainColor;
        display: block;
        cursor: help;
    }

    .add {
        cursor: pointer;
        height: 200px;
        width: 200px;
        background-color: $highlightColor;
        margin: 40px auto 0;
        line-height: 200px;
        font-size: 50px;
        font-weight: bold;
        border-radius: 50px;
        color: $whiteColor;
        border: 4px dashed $whiteColor;
        transition: all 0.2s ease-out;

        &:hover {
            border-style: groove;
        }

        &:active {
            border-style: solid;
        }
    }
}

.cosmosContainer {
    width: 100%;
    height: 100%;
    background: $highlightColor;
}
