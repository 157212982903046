@import "./../../styles/colors";
@import "./../../styles/animations";

.container {
    .buttons {
        margin-bottom: 16px;
        button {
            padding: 8px 16px;
            font-size: 20px;

            &.languageSelected {
                font-weight: bold;
            }
        }
    }

    .goldenSnitch {
        display: inline-block;
        color: gold;
        font-weight: bold;
        animation: goldensnitch 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite alternate;
    }
}

.cosmosContainer {
    width: 100%;
    height: 100%;
    background: $highlightColor;
}
