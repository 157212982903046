@import "./../../styles/colors";

.container {
    text-align: center;
    overflow: auto;

    .title {
        padding-top: 8px;
        text-align: center;
    }

    .welcomeImage {
        border-radius: 8px;
        object-fit: contain;
        width: 100%;
    }

    .bottle {
        margin: 0 auto;
    }

    .link {
        margin: 36px auto 0;
        display: block;
        color: $whiteColor;
        padding: 32px 0;
        font-weight: bold;
        border-radius: 20px;
        background: linear-gradient(145deg, #407679, #366366);
        box-shadow: 5px 5px 10px #2a4d4f, -5px -5px 10px #4e8f93;
        transition: all 0.2s ease;
        max-width: 240px;
        width: 60%;
        user-select: none;
        text-align: center;

        &:last-child {
            margin-bottom: 16px;
        }

        &:hover {
            background: linear-gradient(145deg, #366366, #407679);
            box-shadow: 5px 5px 10px #2a4d4f, -5px -5px 10px #4e8f93;
        }

        &:active {
            background: #3c6e71;
            box-shadow: inset 5px 5px 10px #2a4d4f, inset -5px -5px 10px #4e8f93;
        }
    }
}

.cosmosContainer {
    width: 100%;
    height: 100%;
    background: $highlightColor;
}
