@import "./../../styles/colors";

$small: 30px;
$medium: 50px;
$large: 70px;

.container {
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, $greyColor 5%, $accentColor 60%, $highlightColor 97%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0;
    animation: fadein 0.5s forwards ease-in-out;
}

.bubblesContainer {
    display: flex;

    // @TODO random generate these animation timings
    .bubbleHorizontal {
        &:nth-child(1) {
            animation: wobble 3s 275ms infinite ease-in-out;

            .bubble {
                animation: up 6.5s infinite;
            }
        }

        &:nth-child(2) {
            animation: wobble 4s 400ms infinite ease-in-out;

            .bubble {
                animation: up 5.5s 310ms infinite;
            }
        }

        &:nth-child(3) {
            animation: wobble 4s 205ms infinite ease-in-out;

            .bubble {
                animation: up 4s 320ms infinite;
            }
        }

        &:nth-child(4) {
            animation: wobble 2.5s 300ms infinite ease-in-out;

            .bubble {
                animation: up 6s 175ms infinite;
            }
        }

        &:nth-child(5) {
            animation: wobble 2.25s 100ms infinite ease-in-out;

            .bubble {
                animation: up 6.7s 225ms infinite;
            }
        }

        &:nth-child(6) {
            animation: wobble 1.25s 450ms infinite ease-in-out;

            .bubble {
                animation: up 3.8s 200ms infinite;
            }
        }
    }

    .bubble {
        background: url("https://milvum.com/wp-content/uploads/2018/01/Milvum-Logo-wit-2x.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: $small;
        width: $small;
        opacity: 0;
        will-change: transform, opacity;

        &.small {
            height: $small;
            width: $small;
        }

        &.medium {
            height: $medium;
            width: $medium;
        }

        &.large {
            height: $large;
            width: $large;
        }
    }
}

@keyframes up {
    0% {
        opacity: 0;
    }

    10%,
    20% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(-50vh);
    }
}

@keyframes wobble {
    33% {
        transform: translateX(-50px);
    }

    66% {
        transform: translateX(50px);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.cosmosContainer {
    width: 100%;
    height: 100%;
    background: $highlightColor;
}
