@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap");

@import "./../../styles/colors";

$blockWidth: 300px;
$blockHorizontalMargin: 16px;
$blockTotalSize: $blockWidth + 2 * $blockHorizontalMargin;
$maxBlocksPerRow: 10;

.container {
    padding: 16px 0;

    .search {
        width: 90%;
        max-width: 600px;
        height: 40px;
        border-radius: 2px;
        padding: 0 8px;
        color: $mainColor;
        font-size: 20px;
        font-weight: bold;
        margin: 0 auto;
        display: block;
    }

    .list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 64px auto 0;
        width: $maxBlocksPerRow * $blockTotalSize;

        // Generate media queries to set the container width based on the screen width
        // This is to create a wrapping container that is horizontally centered in parent and the items are left algined.
        @for $i from 0 through $maxBlocksPerRow {
            @media only screen and (min-width: $i * $blockTotalSize) and (max-width: ($i + 1) * $blockTotalSize) {
                width: max($i * $blockTotalSize, $blockTotalSize);
            }
        }

        .item {
            height: 400px;
            width: $blockWidth;
            margin: 0 $blockHorizontalMargin 32px;
            background-color: $whiteColor;
            border-radius: 2px;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: rgba(0, 0, 0, 0.7) 0px 4px 12px;
            transition: all 0.3s ease-out;
            transform-origin: top center;

            &:nth-child(7n + 4) {
                transform: translateY(-2px) rotate(2.5deg);
            }

            &:nth-child(7n + 1) {
                transform: translateY(-1px) rotate(-2.8deg);
            }

            .photo {
                margin-top: 30px;
                background-color: $greyColor;
                overflow: hidden;
                border-radius: 2px;
                width: 240px;
                height: 280px;
                flex-shrink: 0;
                object-fit: contain;
            }

            .name {
                display: inline-block;
                font-family: "Caveat", cursive;
                color: $accentColor;
                text-align: right;
                font-size: 50px;
                font-weight: bold;
                margin-right: 40px;
                flex-shrink: 0;
                align-self: flex-end;
            }
        }
    }
}

.cosmosContainer {
    width: 100%;
    height: 100%;
    background: $highlightColor;
}
