@import "./../../../styles/colors";

.container {
    background: $sixblocksOrange;
    padding: 16px 2px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease;
    animation: grow 0.3s forwards ease-out;

    .clusterTitle {
        background: transparent;
        border: none;
        font-weight: bold;
        text-align: center;
        padding: 8px 16px;
        margin: 0 8px;
        transition: all 0.2s ease;
        border-radius: 8px;
        margin-bottom: 16px;

        &:focus {
            background: $whiteColor;
        }
    }

    .cell {
        display: flex;
        align-items: center;
        animation: expand 0.3s forwards ease-out;
        transform-origin: top center;
        position: relative;

        + .cell {
            margin-top: 32px;

            &:after {
                content: "";
                height: 50px;
                width: 100%;
                position: absolute;
                background: url("../../../assets/images/ArrowDown.png");
                bottom: 56px;
                background-position: center bottom;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        .circlePort {
            width: 8px;
            height: 8px;
            background: black;
            border-radius: 50%;
            flex-shrink: 0;

            &:hover {
                background-color: $whiteColor;
            }
        }
        .cellName {
            background: $whiteColor;
            border: $mainColor 1px solid;
            text-align: center;
            border-radius: 8px;
            padding: 24px 8px;
            margin: 0 8px;
            flex: 1;
        }
    }
}

.cosmosContainer {
    width: 100%;
    height: 100%;
    background: $highlightColor;
}

@keyframes expand {
    0% {
        transform: scaleY(0);
        opacity: 0.2;
    }

    100% {
        transform: scaleY(1);
        opacity: 1;
    }
}

@keyframes grow {
    0% {
        transform: scale(0);
        opacity: 0.2;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
