@import "./../../styles/colors";
@import "./../../styles/animations";

.container {
    height: 200px;
    width: 100px;
    display: inline-block;

    .bells,
    .bottle {
        height: 50%;
        width: 100%;
        position: relative;
    }

    .bells {
        .bell {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom;
            position: absolute;
            height: 30px;
            width: 30px;
            bottom: 4px;
            animation: float 5s ease infinite;

            &:nth-child(1) {
                background-image: url("../../assets/images/bel-1.png");
                left: 23%;
                background-size: 36%;
                animation-duration: 5.82s;
            }

            &:nth-child(2) {
                background-image: url("../../assets/images/bel-2.png");
                left: 42%;
                background-size: 55%;
                animation-duration: 4.73s;
            }

            &:nth-child(3) {
                background-image: url("../../assets/images/bel-3.png");
                left: 16%;
                animation-duration: 3.22s;
            }
        }
    }

    .bottle {
        background-image: url("../../assets/images/fles.png");
        background-repeat: no-repeat;
        background-size: contain;

        .bubble {
            $bubbleSize: 10px;

            background: $whiteColor;
            height: $bubbleSize;
            width: $bubbleSize;
            border-radius: 50%;
            position: absolute;

            &:nth-child(1) {
                bottom: 12%;
                left: 21%;
                animation: bubbleVertical 1.45s alternate infinite;
            }

            &:nth-child(2) {
                bottom: 15%;
                left: 59%;
                animation: bubbleHorizontal 1.93s alternate infinite;
            }
        }
    }
}

.cosmosContainer {
    width: 100%;
    height: 100%;
    background: $highlightColor;
}
