@import "./../../styles/colors";

.container {
    height: 100%;
    display: flex;
    background: $whiteColor;
    flex-direction: column;

    .buttons {
        padding: 16px;
        position: relative;
        display: flex;
        align-items: center;
        background-color: $whiteColor;
        box-shadow: 0px -3px 35px -6px rgba(0, 0, 0, 0.75);

        .title {
            flex: 1;
            color: $mainColor;
            font-weight: bold;
            font-size: 24px;
            line-height: 24px;
        }

        .button {
            background: $accentColor;
            padding: 8px 16px;
            border-radius: 4px;
            margin-right: 16px;
            flex-shrink: 0;
        }

        .clusterButton {
            background: $accentColor;
            width: 32px;
            height: 32px;
            border-radius: 8px;
            margin-right: 16px;
            flex-shrink: 0;
            cursor: grab;

            &.orange {
                background: $sixblocksOrange;
            }

            &.blue {
                background: $sixblocksBlue;
            }

            &.miniCell {
                background: $whiteColor;
                border: $mainColor 1px solid;
            }
        }
    }

    .layer {
        flex: 1;
        display: flex;
        .canvas {
            flex: 1;
        }
    }
}

.cosmosContainer {
    width: 100%;
    height: 100%;
    background: $highlightColor;
}
